<template>
  <div
    class="mb-0"
    :class="$vuetify.breakpoint.name === 'lg' ? 'pr-12 pl-8' : 'px-4'">

    <!-- ==== DIALOG DETAIL ==== -->
    <v-dialog v-model="dialog.detail" persistent width="450">
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-2 white--text"
          style="background-color: #d31145;"
        >
          Detail {{ detail.payment_method }}
        </v-card-title>
        <v-card-text class="pt-5">
          <v-data-table
            flat
            class="laporan hide_sort"
            :class="$vuetify.breakpoint.name === 'xs' ? 'mt-0' : 'mt-0'"
            :headers="headers_detail"
            :loading="loading"
            hide-default-footer
            disable-pagination
            :items="detail.detail_array"
          >

            <template v-slot:[`item.grand_total_sum`]="{ item }">
              {{ item.grand_total_sum }}
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="px-5 py-3">
          <v-spacer />
          <v-btn
            small
            outlined
            color="#d31145"
            class="text-capitalize"
            @click="dialog.detail = false"
          >
            <v-icon color="#d31145" small class="mr-1"
              >mdi-close-circle</v-icon
            >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    

    <!-- ==== DATA PENJUALAN PER METODE PEMBAYARAN ==== -->
    <div class="my-5">
      <div class="title text--secondary mb-3">
        Data Penjualan Per Metode Pembayaran
      </div>
      <div class="mb-3 d-flex align-center flex-wrap">

        <Export
          class=" mr-2"
          :disabled="$store.state.loading"
          :file_type="'xls'"
          :btnText="'Export Excel'"
          :btnIcon="'mdi-microsoft-excel'"
          :btnColor="'teal'"
          :url="`franchise/report/payment-method/export_excel?store_id=${selected_store_id}`"
          :title="'Laporan Penjualan Per Metode Pembayaran'"
          ref="base-export-excel"
          v-on:onClickExport="responseExport"
        />
        <Export
          class=" mr-2"
          :disabled="$store.state.loading"
          :file_type="'pdf'"
          :btnText="'Export PDF'"
          :btnIcon="'mdi-file-pdf-box'"
          :btnColor="'#f40f02'"
          :url="`franchise/report/payment-method/export_pdf?store_id=${selected_store_id}`"
          :title="'Laporan Penjualan Per Metode Pembayaran'"
          ref="base-export-pdf"
          v-on:onClickExport="responseExport"
        />

        <v-btn
          depressed
          :fab="$vuetify.breakpoint.name !== 'xs' ? false : true"
          :x-small="$vuetify.breakpoint.name !== 'xs' ? false : true"
          :width="$vuetify.breakpoint.name === 'xs' ? '' : '150'"
          elevation="0"
          :disabled="process.run"
          color="#aaa9ad"
          class="white--text text-capitalize"
          @click="$refs['base-table'].isFilterVisible = true"
        >
          <span v-if="$vuetify.breakpoint.name !== 'xs'">Filter</span>
          <v-icon v-if="$vuetify.breakpoint.name === 'xs'">mdi-filter-variant</v-icon>
        </v-btn>
      </div>

      <!-- ==== FILTER STATUS ==== -->
      <div v-if="status.date || status.payment">
        Pencarian :
        <v-chip
          v-if="status.date"
          class="ma-2"
          close
          @click:close="handleCloseChips('date')"
        >
          Tanggal: {{ filterdate.start | dateFull }} - {{ filterdate.end | dateFull }}
        </v-chip>
        <v-chip
          v-if="status.payment"
          class="ma-2"
          close
          @click:close="handleCloseChips('payment')"
        >
          Payment: {{ filternamepayment }}
        </v-chip>
      </div>

      <!-- ==== DATA SUMMARY ==== -->
      <v-expansion-panels flat accordion v-model="panel" focusable>
        <v-expansion-panel expand v-model="panel">
          <v-expansion-panel-header
            class="title text--secondary"
            style="font-size:1rem !important;"
            >Rincian Penjualan Per Metode Pembayaran</v-expansion-panel-header
          >
          <v-expansion-panel-content
            class="pt-5 mb-2"
            style=" box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; border-radius:5px;"
          >
            <v-row>
              <v-col cols="12" sm="6" :class="$vuetify.breakpoint.name === 'xs' ? 'px-1' : ''"
                v-for="(item, key) in total" :key="key">
                <v-card class="box-shadow border-radius pa-2">
                  <v-card-actions class="pt-2 px-0">
                    <div style="width:100%">
                      <v-card-title
                        class="font-weight-bold text--secondary px-0 py-0 pb-1"
                        :style="$vuetify.breakpoint.name === 'xs' ? 'font-size: 11px; line-height: 1; height: 30px' : ''"
                        :class="$vuetify.breakpoint.name === 'xs' ? '' : 'body-2'"
                      >
                        {{ item.label }}
                        <v-spacer></v-spacer>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="#d31145"
                              v-if="item.note.title != ''"
                              v-on="on"
                              v-bind="attrs"
                              size="20"
                              >mdi-help-circle</v-icon
                            >
                          </template>
                          <div class="d-flex flex-column">
                            <span class="font-weight-bold"><b>{{item.note.title}}</b></span>
                            <span>{{ item.note.content }}</span>
                          </div>
                        </v-tooltip>
                      </v-card-title>
                      <v-card-text :class="$vuetify.breakpoint.name === 'xs' ? 'title text-second px-0' : 'headline text-second px-0'">
                        {{ item.value }}
                      </v-card-text>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- ==== LIST DATA PENJUALAN PER METODE PENJUALAN ==== -->
      <div v-show="process.run && list.length < 1">
        <v-skeleton-loader
          :loading="process.run"
          transition="scale-transition"
          :tile="false"
          type="table-tbody"
        >
        </v-skeleton-loader>
      </div>

      <!-- ==== BASE TABLE ==== -->

      <base-table
        v-on:onResetFilter="resetFilter"
        v-on:onRetrieveDataStart="responseDataStart"
        v-on:onRetrieveDataEnd="responseDataEnd"
        v-show="displayBaseTable"
        :isExternalFilterButton="true"
        :isFilterOnHeader="true"
        ref="base-table"
        dateType="date"
        dateFormat="DD MMMM YYYY"
        :customHeight="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '' : 'calc(100vh - 450px)'"
        :filterHeader="filterHeader_salesPayment"
        :single-select="singleSelect"
        :headers="headers"
        :retrieveDataURL="`franchise/report/payment-method?store_id=${selected_store_id}`"
        filterType="laporan"
      >

        <template v-slot:[`item.aksi_table`]="{ item }">
          <v-tooltip bottom v-if="item.detail_array.length > 0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                small
                text
                :min-width="$vuetify.breakpoint.name === 'sm' ? 25 : 30"
                class="text-capitalize orange--text text--darken-1 pa-0 mr-1 c-p"
                @click="
                  $vuetify.breakpoint.name === 'xs'
                    ? toDetail(item)
                    : $vuetify.breakpoint.name === 'sm'
                    ? toDetail(item)
                    : toDetail(item)
                "
              >
                <v-icon :size="$vuetify.breakpoint.name === 'sm' ? 20 : ''">mdi-information-outline</v-icon>
              </v-btn>
            </template>
            <span>Detail</span>
          </v-tooltip>
          <div v-if="item.detail_array.length < 1">
            -
          </div>
        </template>
              
        <template v-slot:[`item.count`]="{ item }">
          {{ item.count | num_format }}
        </template> 

        <template v-slot:[`item.grand_total_sum`]="{ item }">
          {{ item.grand_total_sum }}
        </template> 

        <template v-slot:filterTitle>
          Filter Ringkasan Berdasarkan Metode Pembayaran
        </template>

        <template v-slot:customFilter>

          <VueCtkDateTimePicker
            v-model="filterdate"
            label="Pilih Tanggal"
            :range="true"
            noClearButton
            color="#d31145"
            format="YYYY-MM-DD"
            formatted="LL"
            locale="id"
            :max-date="todays"
            :custom-shortcuts="[
              { key: 'today', label: 'Hari ini', value: 'day' },
              { key: 'thisWeek', label: 'Minggu ini', value: 'isoWeek' },
              { key: 'lastWeek', label: 'Minggu lalu', value: '-isoWeek' },
              { key: 'last7Days', label: '7 hari terakhir', value: 7 },
              { key: 'last30Days', label: '30 hari terakhir', value: 30 },
              { key: 'thisMonth', label: 'Bulan ini', value: 'month' },
              { key: 'lastMonth', label: 'Bulan lalu', value: '-month' },
              { key: 'thisYear', label: 'Tahun ini', value: 'year' },
              { key: 'lastYear', label: 'Tahun lalu', value: '-year' },
            ]"
          />

          <v-select
            class="mt-5"
            :items="item_filterpayment"
            item-text="name"
            item-value="id"
            outlined
            clearable
            dense
            color="#d31145"
            label="Metode Pembayaran"
            v-model="filterpayment"
          >
          </v-select>
        </template>
      </base-table>

      <!-- ==== COMPONENT DATA KOSONG ==== -->
      <Empty v-show="emptyData" />
    </div>
  </div>
</template>

<script>
import { get, post, put, destroy, exportExcel } from "@/service/Axios";
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
import moment from "moment";
import Export from "@/components/Export";
export default {
  data() {
    return {
      panel: "",
      todays: new Date().toISOString().substr(0, 10),
      /**
       * DATA COMPONENT BASETABLE
       */

      displayBaseTable: false,
      emptyData: false,
      singleSelect: true,
      dataPagination: {
        current: 0,
        limit: 0,
        total_data: 0,
        page: 1,
        itemsPerPage: 10,
      },
      detail: {},
      headers: [
        {
          text: "Aksi",
          sortable: false,
          width: "60",
          value: "aksi_table",
          align: "center",
        },
        {
          text: "Metode Pembayaran",
          width: 200,
          align: "left",
          value: "payment_method",
        },
        {
          text: "Jumlah Transaksi",
          width: 130,
          align: "center",
          value: "count",
        },
        {
          text: "Total Nominal Transaksi (Rp)",
          width: 180,
          align: "right",
          value: "grand_total_sum",
        },
      ],

      item_filterpayment: [],
      /**
       * END DATA COMPONENT BASETABLE
       */

      filterpayment: "",
      filternamepayment: "",
      filterdate: {},
      options: {},
      totalData: 8,
      loading: false,
      hari: new Date().toISOString().substr(0, 10),
      headers_detail: [
        {
          text: "Nama",
          width: 200,
          align: "left",
          value: "payment_bank_name",
        },
        {
          text: "Jumlah (Rp)",
          width: 130,
          align: "center",
          value: "grand_total_sum",
        },
      ],
      process: {
        run: false,
        loading: false,
      },
      filterSave: {
        date: {},
        payment: "",
      },
      status: {
        showTimeRangePanel: false,
        date: false,
        payment: false,
      },
      dialog: {
        filtered: false,
        detail: false
      },
      store: [],
      cashier: [],
      list: [],
      total: {},
      pagination: {
        data: {},
        page: "",
      },
      limit: 10,
      page: 1,
      totalData: 0,
      selected: {
        pagination: 0,
      },
    };
  },
  components: {
    Empty,
    Pagination,
    Export,
  },
  computed: {
    filterHeader_salesPayment() {
      return [
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "custom",
          column: "",
          valueDefault: "",
          label: "",
          class: "",
        },

        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
      ];
    },

    selected_store_id () {
      return JSON.parse(localStorage.getItem('selected_store_id'));
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  watch: {
    // "selected.pagination": function(val) {
    //   this.initialize(val + 1);
    // },
    // options: {
    //   handler() {
    //     this.initialize();
    //   },
    //   deep: true,
    // },
  },
  created() {
    this.fetchPaymentMethod()
  },
  methods: {
    /**
     * EVENTS
     */
    toDetail(item) {
      this.detail = item
      this.dialog.detail = true
    },
    resetFilter() {
      this.$refs["base-table"].params.start_date &&
        delete this.$refs["base-table"].params.start_date;
      this.$refs["base-table"].params.end_date &&
        delete this.$refs["base-table"].params.end_date;
      this.$refs["base-table"].params.payment_method &&
        delete this.$refs["base-table"].params.payment_method;

      this.status.date = false;
      this.filterdate = [];

      this.status.payment = false;
      this.filterpayment = "";

      this.$refs["base-table"].retrieveData();
    },

    clearFilterChip(type) {
      switch (type) {
        case 'date':
          delete this.$refs["base-table"].params["filter[0][type]"];
          delete this.$refs["base-table"].params["filter[0][field]"];
          delete this.$refs["base-table"].params["filter[0][comparison]"];
          delete this.$refs["base-table"].params["filter[0][value]"];
          break;
        case 'payment':
          delete this.$refs["base-table"].params["filter[1][type]"];
          delete this.$refs["base-table"].params["filter[1][field]"];
          delete this.$refs["base-table"].params["filter[1][comparison]"];
          delete this.$refs["base-table"].params["filter[1][value]"];
          break;
        default:
          break;
      }

      this.$refs["base-table"].retrieveData();
      this.resetFilter();
    },

    handleCloseChips(mode) {
      switch (mode) {
        case "date":
          this.status.date = false;
          this.filterdate = {};
          this.resetFilter();
          // this.$refs["base-table"].retrieveData();
          this.clearFilterChip(mode)
          break;

        case "payment":
          this.status.payment = false;
          this.filterpayment = "";
          this.filternamepayment = "";
          this.resetFilter();

          // this.$refs["base-table"].retrieveData();
          this.clearFilterChip(mode)
          break;
      }

      if (this.status.date ||
            this.status.payment) {
        
      } else {
        if (this.$route.query.filter !== undefined) {
          this.$router.replace({ path: `${this.$route.path}` })
        }
      }
    },

    responseDataStart() {
      this.loading = true;
      this.process.run = true;
      this.displayBaseTable = false;
      this.emptyData = false;
      /**
       * contains handler for filter params
       * date,method_payment
       */

      //DATE
      let item = [];
      if (Object.keys(this.filterdate).length != 0) {
        //get each item
        
        //assign params to item
        this.$refs["base-table"].params.start_date = this.filterdate.start
        this.$refs["base-table"].params.end_date = this.filterdate.end
        //show chips
        this.status.date = true;
      }

      //METHOD PAYMENT
      if (this.filterpayment) {
        this.$refs["base-table"].params["filter[1][type]"] = "string";
        this.$refs["base-table"].params["filter[1][field]"] = "payment_method_id";
        this.$refs["base-table"].params["filter[1][comparison]"] = "==";
        this.$refs["base-table"].params["filter[1][value]"] = this.filterpayment;

        this.item_filterpayment.forEach(item => {
          this.status.payment = true;
          if (item.id == this.filterpayment) {
            // console.log(item);
            this.filternamepayment = item.name;
          }
        })
      }
    },

    responseDataEnd(res) {
      this.loading = false;
      this.process.run = false;
      this.fetchSummaryData(this.selected_store_id)
      if (res.data.length <= 0) {
        this.emptyData = true;
        this.displayBaseTable = false;
      } else {
        this.emptyData = false;
        this.displayBaseTable = true;
      }
      this.contentEmpty = "Data tidak ditemukan";
    },

    async fetchSummaryData (store_id) {
      let start_date = this.filterdate.start ? this.filterdate.start : ''
      let end_date = this.filterdate.end ? this.filterdate.end: '' 

      const response  = await get(`franchise/report/payment-method/summary?`, {
        params: {
          store_id,
          start_date,
          end_date,
          payment_method_id: this.filterpayment
        }
      })

      let res = response.data

      if (res.status == 200) {
        this.total = res.results.data
      }
    },

    responseExport() {
      //send params from base table to base export
      this.$refs["base-export-excel"].params = this.$refs["base-table"].params;
      this.$refs["base-export-pdf"].params = this.$refs["base-table"].params;

      // //KALAU MAU PAKE SEARCH
      // this.$refs["base-export"].params.search = this.$refs[
      //   "base-table"
      // ].searchVal;
    },
    /**
     * END EVENTS
     */

    disabledAfterThisDay(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },
    async Export() {
      this.process.formExport = true;
      this.process.formExport = false;
    },

    fetchPaymentMethod() {
      get(`franchise/store/payment_method`, {
        params: {
          store_id: this.selected_store_id,
          pagination_bool: false,
        },
      })
        .then((response) => {
          let res = response.data;
          if (res.status === 200) {
            this.item_filterpayment = res.results.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            let errorData = error.response.data;
            let errorCode = error.response.status;
            let errorHeader = error.response.headers;

            if (errorCode === 401) {
              this.$store.state.unauthorization = true;
              this.process.run = false;
              this.process.form = false;
            }
          } else {
            // this.$store.state.errorNetwork = true;
            this.process.run = false;
            this.process.form = false;
          }
        });
    },
    
    
    changePage(event) {
      if (event === undefined) {
        this.selected.pagination = 0;
      }
    },
    
  },
};
</script>
<style scoped>
.background-bank-cols {
  background-color: #cfd8dc;
  border: 3px solid #cfd8dc;
  border-radius: 10px;
}
</style>
